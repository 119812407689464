/* @font-face {
    font-family: 'DIN Next LT Arabic';
    src:
        url('./arb-font/ArbFONTS-DINNextLTArabic-Black-2.ttf') format('truetype'),
        url('./arb-font/ArbFONTS-DINNextLTArabic-Black-3.ttf') format('truetype'),
        url('./arb-font/ArbFONTS-DINNextLTArabic-Black-4.ttf') format('truetype'),
        url('./arb-font/ArbFONTS-DINNextLTArabic-Bold-2.ttf') format('truetype'),
        url('./arb-font/ArbFONTS-DINNextLTArabic-Bold-3.ttf') format('truetype'),
        url('./arb-font/ArbFONTS-DINNextLTArabic-Bold-4.ttf') format('truetype'),
        url('./arb-font/ArbFONTS-DINNextLTArabic-Heavy-1.ttf') format('truetype'),
        url('./arb-font/ArbFONTS-DINNextLTArabic-Heavy2-1.ttf') format('truetype'),
        url('./arb-font/ArbFONTS-DINNextLTArabic-Heavy2-2.ttf') format('truetype'),
        url('./arb-font/ArbFONTS-DINNEXTLTARABIC-LIGHT-1.ttf') format('truetype'),
        url('./arb-font/ArbFONTS-DINNEXTLTARABIC-LIGHT-2-2.ttf') format('truetype'),
        url('./arb-font/ArbFONTS-DINNextLTArabic-Medium-2.ttf') format('truetype'),
        url('./arb-font/ArbFONTS-DINNextLTArabic-Medium-3.ttf') format('truetype'),
        url('./arb-font/ArbFONTS-DINNextLTArabic-Medium-4.ttf') format('truetype'),
        url('./arb-font/ArbFONTS-DINNextLTArabic-Regular-2.ttf') format('truetype'),
        url('./arb-font/ArbFONTS-DINNextLTArabic-Regular-3.ttf') format('truetype'),
        url('./arb-font/ArbFONTS-DINNextLTArabic-Regular-4.ttf') format('truetype'),
        url('./arb-font/ArbFONTS-DINNextLTArabic-UltraLight-1.ttf') format('truetype'),
        url('./arb-font/ArbFONTS-DINNextLTArabic-UltraLight-2.ttf') format('truetype'),
        url('./arb-font/ArbFONTS-DINNextLTArabic-UltraLight-3.ttf') format('truetype'),
        url('./arb-font/ArbFONTS-DINNextLTArabic-UltraLight-4.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
} */

/* @font-face {
    font-family: 'DIN Next LT Arabic';
    src: url('./arb-font/ArbFONTS-DINNextLTArabic-Black-2.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
} */

/* @font-face {
    font-family: 'DIN Next LT Arabic';
    src: url('./arb-font/ArbFONTS-DINNextLTArabic-Black-2.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
} */

/* Arabic Fonts */
@font-face {
  font-family: ar-900;
  src: url("./arb-font/ArbFONTS-DINNextLTArabic-Black-4.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: ar-800;
  src: url("./arb-font/ArbFONTS-DINNextLTArabic-Black-3.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: ar-700;
  src: url("./arb-font/ArbFONTS-DINNextLTArabic-Black-2.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: ar-600;
  src: url("./arb-font/ArbFONTS-DINNextLTArabic-Bold-4.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: ar-500;
  src: url("./arb-font/ArbFONTS-DINNextLTArabic-Bold-3.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: ar-400;
  src: url("./arb-font/ArbFONTS-DINNextLTArabic-Regular-3.ttf")
    format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: ar-300;
  src: url("./arb-font/ArbFONTS-DINNextLTArabic-Regular-3.ttf")
    format("truetype");
  font-weight: 300;
}

/* English Fonts */

@font-face {
  font-family: en-700;
  src: url("./eng-font/HagridText-Bold.otf") format("truetype");
  font-weight: 700;
}
