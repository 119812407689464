/* Global Rules */

* {
  /* scroll-behavior: smooth; */
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  /* font-family: var(--en-medium), sans-serif; */
}

/* @media screen and (max-width: 900px) {
    body {
        overflow-x: hidden !important;
    }
} */

::selection {
  background-color: var(--main-color);
  color: white;
}

body {
  background-color: var(--bg-color);
  overflow-x: hidden !important;
  font-family: "Poppins", sans-serif !important;
  transition: background-color 0.3s ease-in-out;
  -webkit-transition: background-color 0.3s ease-in-out;
  -moz-transition: background-color 0.3s ease-in-out;
  -ms-transition: background-color 0.3s ease-in-out;
  -o-transition: background-color 0.3s ease-in-out;
}

.dir-rtl {
  direction: rtl;
  font-family: var(--ar-regular), sans-serif !important;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

section:not(:first-of-type) {
  padding: 120px 0 0;
}

/* Track */
::-webkit-scrollbar {
  width: 8px;
  /* width of the scrollbar */
}

/* Handle */
::-webkit-scrollbar-thumb {
  /*colorofthescrollbarhandle*/
  background-color: var(--main-color);
  /* background-color: transparent; */
  /* border-radius: var(--box-rad);
    -webkit-border-radius: var(--box-rad);
    -moz-border-radius: var(--box-rad);
    -ms-border-radius: var(--box-rad);
    -o-border-radius: var(--box-rad); */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #dd7600;
  /* color of the scrollbar handle on hover */
}

/* Track */
::-webkit-scrollbar-track {
  /* background-color: transparent; */
  background-color: var(--text-color);
  padding: 0 15px;
  /* color of the scrollbar track */
}

/* Variables */
:root {
  /* Color Variables */
  /* --main-color: #fd8900; */
  --main-color: #fd5b00;
  --secondary-color: #2b2f4e;
  /* --bg-color: #0e0c15; */
  --bg-color: #0a0a12;
  --text-p-color: #9f9f9f;
  --text-white: #ffffff;
  --nav-color-change: white;
  --main-radius: 24px;
  --main-transition: 0.5s;
  --landing-border: #1c1c1c;
  --landing-h3: #fd5b00;
  --btn-color: white;
  --btn-bg: #fd5b00;
  --btn-work: #1d1d25;
  /* --icons-bg: rgba(135, 135, 135, 0.1); */
  --icons-bg: rgba(29, 36, 57, 0.4);
  --filter-bg: var(--material-bg, #0a1020);
  --contact-bg: #0a1020;
  --scm-bg: #0a1020;
  --social-media-icon-bg: rgba(255, 255, 255, 0.03);
  --footer-border: rgba(52, 52, 52, 1);
  --arrow-color: white;
  --svg-btn-color: white;
  --sctb-bg: #2b2f4e;
  --searchAfterElement: #3e3f54;
  --skeleton-bg: #19171e;
  /* New */
  --about-bg: #11111d;
  --about-btn-bg: #282b43;
  --serv-bg: #11111d;
  --crtf-bg: #191d30;
  --crtf-text-color: #7581bd;
  --border: #212139;
  --mainHeadingShape: #39393942;
  --workBtn: #151524;
  /* Fonts Variables */
  --ar-normal: ar-400;
  --ar-regular: ar-500;
  --ar-bold: ar-700;

  /* Style Variables */
  --line-height: 2;
}

.light-mode {
  /* --blue-bg-color: #111827;
    --blue-light-color: #111827;
    --btn-text-color: #0072C6;
    --footer-text-color: #111827;
    --footer-bg: #0072C6;
    --bg-white-slide: transparent;
    --btn-bg-color: transparent;
    --footer-text-gradient-color: #111827; */

  /* My site */
  --sctb-bg: #e8e8e8;
  --social-media-icon-bg: rgba(0, 0, 0, 0.03);
  --searchAfterElement: #c4c4c4;
  --text-color: #fdfdfd;
  --text-black: #fdfdfd;
  --text-white: rgba(43, 47, 78, 1);
  --text-p-color: #656565;
  --bg-color: white;
  --landing-border: #d6d6d6;
  --landing-h3: white;
  --btn-bg: #fd5b00;
  --btn-work: #ededed;
  --svg-btn-color: white;
  --icons-bg: rgba(232, 232, 232, 1);
  --filter-bg: #f3f3f3;
  --contact-bg: #ededed;
  --scm-bg: #f6f6f6;
  --footer-border: #e7e7e7;
  --arrow-color: #2b2f4e;
  --skeleton-bg: #c7c7c7;
  /* New */
  --about-bg: #f2f2f2;
  --about-btn-bg: #ccc;
  --serv-bg: #f9f9f9;
  --crtf-bg: #e7e7e7;
  --border: #dfdfdf;
  --crtf-text-color: rgba(43, 47, 78, 1);
  --mainHeadingShape: #c7c7c7;
  --workBtn: #13141f;
}

/* Global Classes (Framework) */
@media screen and (max-width: 650px) {
  .flex-center-column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
}

/* Media Queries */

@media (max-width: 767px) {
  .container {
    /* width: 750px; */
    /* margin: 0 auto; */
    width: 100%;
    padding: 0 24px;
  }
}

@media (min-width: 768px) {
  .container {
    /* width: 750px; */
    /* margin: 0 auto; */
    width: 100%;
    padding: 0 70px;
  }
}

/* Medium */
@media (min-width: 992px) {
  .container {
    /* width: 970px; */
    /* padding: 0 140px; */
    width: 100%;
    padding: 0 140px !important;
  }
}

/* Small */
@media (min-width: 1200px) {
  .container {
    width: 100%;
    padding: 0 140px;
  }
}

/* Dashboard Styles */

.layout,
.css-1uodbwk-MuiScopedCssBaseline-root,
.css-ycxhju {
  direction: ltr !important;
}

.w-full {
  width: 100% !important;
}
/* Components */

.loading-page {
  background: #1e1e1e;
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.dir-rtl .loading-page {
  font-family: var(--ar-regular);
}

.loading-page .counter {
  text-align: center;
}

.loading-page .counter p {
  font-size: 40px;
  font-weight: 100;
  color: #fd5b00;
}

.loading-page .counter h1 {
  color: transparent;
  font-size: 60px;
  margin-top: 10px;
  margin-bottom: 30px;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 1);
}

.loading-page .counter hr {
  background: #fd5b00;
  border: none;
  height: 1px;
}

.loading-page .counter {
  position: relative;
  width: 600px;
  max-width: 90vw;
}

#myBtn {
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  font-size: 2em;
  position: fixed;
  z-index: 200;
  right: -60px;
  bottom: 50px;
  background-color: var(--sctb-bg);
  box-shadow: 0 4px 50px rgba(1 1 1 / 15%);
  display: none;
  opacity: 95%;
  color: var(--bg-white);
  border: none;
  cursor: pointer;
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
  animation: pop var(--main-transition) ease both;
  -webkit-animation: pop var(--main-transition) ease both;
}

@media screen and (max-width: 768px) {
  #myBtn {
    width: 40px;
    height: 40px;
  }
}

#myBtn svg path {
  fill: var(--arrow-color);
}

#myBtn.clicked {
  animation: slideRight calc(var(--main-transition));
  -webkit-animation: slideRight calc(var(--main-transition));
}

#myBtn:hover {
  color: var(--main-color);
  background-color: var(--main-color);
}

@keyframes pop {
  0% {
    opacity: 0;
    scale: 0%;
  }

  50% {
    scale: 100%;
  }

  100% {
    opacity: 1;
    right: 20px;
  }
}

@keyframes slideRight {
  0% {
    right: 20px;
  }

  50% {
    scale: 90%;
  }

  100% {
    right: -60px;
  }
}
.whatsapp-button,
.messenger-button {
  position: fixed;
  left: 20px;
  bottom: 50px;
  padding: 6px;
  width: 50px;
  height: 50px;
  background-color: #25d366;
  z-index: 101;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.messenger-button {
  bottom: 110px !important;
  background-color: #ffffff;
}

@media screen and (max-width: 992px) {
  .whatsapp-button,
  .messenger-button {
    left: 10px;
    padding: 6px;
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 768px) {
  .whatsapp-button,
  .messenger-button {
    left: 10px;
    padding: 6px;
    width: 40px;
    height: 40px;
  }
  .messenger-button {
    bottom: 100px !important;
  }
}

.main-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  padding: 18px 16px;
  /* font-family: var(--en-bold);
  font-weight: var(--en-bold); */
  font-weight: 700;
  gap: 16px;
  color: var(--btn-color);
  background-color: var(--btn-bg);
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.main-heading h1 {
  color: var(--main-color);
  font-size: 45px;
  font-weight: 750 !important;
  text-align: center;
}
.main-heading h1 span {
  color: white;
  display: inline-block;
  position: relative;
  margin-right: 15px;
}
.dir-rtl .main-heading h1 span {
  margin-left: 15px;
}
.services .main-heading h1 span,
.reviews .main-heading h1 span {
  margin-left: 15px;
  margin-right: 0;
}
.dir-rtl .services .main-heading h1 span,
.dir-rtl .reviews .main-heading h1 span {
  margin-right: 15px;
  margin-left: 0;
}
.main-heading.certificate h1 span {
  margin-right: 15px;
  margin-left: 0;
}
.dir-rtl .main-heading.certificate h1 span {
  margin-left: 15px;
  margin-right: 0;
}
.main-heading h1 span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: -15px;
  top: -15px;
  z-index: -1;
  background-color: var(--mainHeadingShape);
  padding: 15px;
  transform: rotate(1.824deg);
  border-radius: calc(var(--main-radius) + 31px);
  -webkit-border-radius: calc(var(--main-radius) + 31px);
  -moz-border-radius: calc(var(--main-radius) + 31px);
  -ms-border-radius: calc(var(--main-radius) + 31px);
  -o-border-radius: calc(var(--main-radius) + 31px);
  -webkit-transform: rotate(1.824deg);
  -moz-transform: rotate(1.824deg);
  -ms-transform: rotate(1.824deg);
  -o-transform: rotate(1.824deg);
}
.dir-rtl .main-heading h1 span::before {
  top: -7px;
}
.services .main-heading h1 span::before {
  transform: rotate(-3.3deg);
}
.portfolio .main-heading h1 span::before {
  top: -10px;
}

.dir-rtl .main-heading h1 {
  direction: rtl !important;
  font-family: var(--ar-bold) !important;
  font-weight: 900 !important;
}
.main-heading p {
  color: #9f9f9f;
  font-weight: 500;
  text-align: center;
  margin: 17px 0 40px;
}
.dir-rtl .main-heading p {
  direction: rtl !important;
  font-family: var(--ar-normal);
}

/* Header */

.overflow {
  overflow: scroll !important;
}

header {
  position: fixed;
  width: 100%;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  top: 0;
}

header.MuiPaper-root {
  min-height: unset;
}

header .burger-icon {
  display: none;
}

@media screen and (max-width: 1325px) {
  header li:last-child {
    display: block;
  }

  header .burger-icon {
    position: relative;
    height: 30px;
    display: block;
  }

  header .burger-icon-toggle {
    display: none;
  }

  header .burger-icon-toggle-label {
    display: block;
    cursor: pointer;
    position: relative;
    z-index: 999;
    width: 30px;
    height: 30px;
  }

  header .icon {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  header .bar {
    width: 100%;
    height: 3px;
    margin: 3px 0;
    background-color: var(--text-white);
    transition: all 0.3s ease;
  }

  header .open .bar:nth-child(2) {
    opacity: 0;
  }

  header .open .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
    -webkit-transform: translateY(8px) rotate(45deg);
    -moz-transform: translateY(8px) rotate(45deg);
    -ms-transform: translateY(8px) rotate(45deg);
    -o-transform: translateY(8px) rotate(45deg);
  }

  header .open .bar:nth-child(3) {
    transform: translateY(-10px) rotate(-45deg);
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
  }
}

header.sticky {
  background-color: rgba(16, 14, 23, 0.2);
  backdrop-filter: blur(22px);
}

header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header .container .nav-links {
  display: flex;
  gap: 50px;
  align-items: center;
}

@media (max-width: 1580px) {
  header .container .nav-links {
    gap: 20px;
  }

  header .container .nav-links a {
    font-size: 14px;
  }
}

@media screen and (max-width: 1325px) {
  header .container .nav-links {
    display: none;
  }

  header .container .nav-links.open {
    position: absolute;
    top: 0;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100vh;
    padding: 140px;
    background: rgba(16, 14, 23, 0.6);
    backdrop-filter: blur(22px) !important;
    gap: 40px;
  }

  header .container .nav-links.open a {
    font-size: 24px;
    color: white !important;
  }

  header .container .nav-links.open a:hover {
    color: var(--main-color) !important;
  }

  header .container .nav-links.open a.active {
    color: var(--main-color) !important;
  }

  header .container .nav-links.open .mood-icon {
    position: relative;
    z-index: 10;
  }

  header .container .dropdown .selected {
    color: white !important;
  }

  header .container .dropdown .menu {
    right: -100px !important;
    top: -40px !important;
    position: relative;
  }

  .dir-rtl header .container .dropdown .menu {
    translate: -180% !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  header .container .nav-links.open {
    padding: 140px 70px;
  }
}

@media screen and (max-width: 768px) {
  header .container .nav-links.open {
    padding: 140px 24px;
  }
}

@media screen and (max-width: 410px) {
  header .container .nav-links.open {
    gap: 25px;
  }
}

@media screen and (max-height: 590px) {
  header .container .nav-links.open {
    gap: 15px;
  }
}

@media screen and (max-height: 510px) {
  header .container .nav-links.open a {
    font-size: 15px;
  }
}

@media screen and (max-width: 376px) {
  header .container .nav-links.open {
    gap: 15px;
  }
}

@media screen and (max-width: 300px) {
  header .container .my-logo {
    width: 150px !important;
  }
}

.my-logo {
  color: var(--text-white);
  /* font-family: Poppins !important; */
  font-family: en-700;
  font-size: 42px;
  font-weight: 700;
  line-height: 38.4px; /* 38.4px */
}

header .container a {
  position: relative;
  z-index: 999;
}

header .container .nav-links img {
  cursor: pointer;
}

header .container .nav-links a {
  color: var(--text-white);
  position: relative;
  z-index: 999;
  font-size: 18px;
  font-weight: 500;
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

header .container .nav-links a:hover {
  color: var(--main-color);
}

header .container .nav-links a.active {
  color: var(--main-color);
}

header .container .dropdown {
  min-width: 80px;
  position: relative;
  z-index: 11;
}

header .container .dropdown .select {
  color: var(--text-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

header .container .dropdown .caret {
  margin: 0 5px;
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

header .container .dropdown .caret-rotate {
  transform: rotate(180);
  -webkit-transform: rotate(180);
  -moz-transform: rotate(180);
  -ms-transform: rotate(180);
  -o-transform: rotate(180);
}

header .container .dropdown .select-clicked {
  box-shadow: 0 0 0 14px #656565;
}

header .container .dropdown .menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  list-style: none;
  padding: 15px 0px;
  /* background-color: var(--secondary-color); */
  background-color: var(--contact-bg);
  /*border: 1px solid #dd7600;
    */
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  color: var(--text-white);
  top: 40px;
  right: 50%;
  translate: 50%;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  /* opacity: 0; */
  display: none;
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
  z-index: 1;
}

header .container .dropdown .menu-open {
  display: block;
  /* opacity: 1; */
  padding: 15px 10px;
  width: 120px;
}

header .container .dropdown .menu li {
  cursor: pointer;
  padding-left: 50px;
  color: var(--text-white);
}

header .container .dropdown .menu li:last-child {
  margin-top: 50px;
}

header .container .dropdown .menu li .lang-wrapper {
  font-family: var(--ar-normal) !important;
  color: var(--text-white);
}

header .container .dropdown .menu li img {
  position: absolute;
  left: 10px;
}

/* Landing */

.circles {
  position: relative;
  z-index: -1;
}

.circles .left-circles {
  position: absolute;
  left: 0;
  top: -100px;
}

.circles .right-circles {
  position: absolute;
  right: 0;
  top: -100px;
  max-width: 100%;
}

.landing {
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--landing-border);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  /* direction: ltr; */
}
.landingBg {
  position: absolute;
  top: 0;
  left: 0;
  aspect-ratio: 16/9;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.landing .container .info {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}

@media (max-width: 760px) {
  .landing .container .info {
    flex-direction: column;
    gap: 80px !important;
    margin-bottom: 26px;
  }

  .light-mode .landing .container .info {
    margin-top: 0px;
  }

  .landing .container .info .text {
    gap: 22px !important;
    color: var(--text-white) !important;
    text-align: center;
  }

  .landing h3 {
    color: var(--main-color) !important;
    font-weight: 700;
  }

  .landing .container .btns a.contact {
    background-color: var(--main-color) !important;
    color: white !important;
    border: 2px solid var(--main-color) !important;
  }

  .light-mode .landing .container .btns a.contact:hover {
    background-color: transparent !important;
    color: #181929 !important;
  }

  .landing .container .btns a.contact:hover {
    background-color: transparent !important;
    color: white !important;
  }

  .landing .container .btns a.contact svg path {
    fill: white;
  }

  .landing .container .btns a.contact:hover svg path {
    fill: white !important;
  }

  .light-mode .landing .container .btns a.contact:hover svg path {
    fill: #181929 !important;
  }

  .landing .container .btns a.work svg path {
    fill: white !important;
  }

  .light-mode .landing .container .btns a.work {
    background-color: transparent !important;
    color: #b7b7b7 !important;
    border: 2px solid #b7b7b7 !important;
  }

  .light-mode .landing .container .btns a.work svg path {
    fill: #b7b7b7 !important;
  }

  .light-mode .landing .container .info .my-photo {
    margin-top: 116px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .landing .container .info .text {
    align-items: center;
    margin: 0 !important;
  }

  .landing .container .btns {
    flex-direction: column;
    gap: 22px !important;
  }

  .landing .container .btns a {
    width: 380px;
  }
}

@media screen and (min-width: 650px) {
  .blog-sun-small.small,
  .contact-sun-small {
    display: none;
  }
}

@media (max-width: 650px) {
  .circles .left-circles,
  .blog-right-circles,
  .contact-right-circles,
  .contact-left-circles,
  .container.reviews .circles {
    display: none !important;
  }

  .blog-sun-small.small {
    position: absolute;
    right: 0;
    top: 0;
    width: 340px;
    z-index: -10;
    rotate: 180deg;
  }

  .contact-sun-small.small {
    position: absolute;
    left: 0;
    top: 30%;
    width: 340px;
    z-index: -10;
  }
}

@media screen and (max-width: 480px) {
  .landing .container .btns,
  .landing .container .btns a.contact,
  .landing .container .btns a.work {
    width: 190px !important;
  }
}

@media (max-width: 350px) {
  .contact-sun-small.small {
    width: 200px;
  }
}

/* @media (min-width: 768px) {
    .landing .container .info .my-photo.display-large-none {
        display: none;
    }
} */

@media (max-width: 768px) {
  .landing .container .info {
    margin-top: 0 !important;
  }
}

@media (max-width: 580px) {
  .landing .eclipse {
    top: 15%;
    height: 400px;
  }

  .landing .container .info .my-photo {
    top: 13px;
    width: 319px !important;
    height: 487px !important;
  }
}

@media (max-width: 350px) {
  .landing .container .info .text h1 {
    font-size: 50px;
  }

  header .container + a {
    margin-right: 10px;
  }

  .landing .container .info .my-photo {
    width: 85% !important;
    object-fit: unset !important;
  }
}

.landing .container .info .my-photo {
  position: relative;
  display: block;
  object-fit: cover;
  height: 824px;
  margin: 116px 0 0 100px;
}
.dir-rtl .landing .container .info .my-photo {
  margin: 116px 100px 0 100px;
}
@media screen and (max-width: 1325px) {
  .landing .container .info .my-photo {
    margin-left: unset;
  }
}

.landing .container .info .text {
  color: var(--text-white);
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 200px;
  flex: 1;
}

@media screen and (max-width: 1425px) {
  .landing .container .info .text {
    margin-right: unset;
  }
}

@media screen and (max-width: 1670px) {
  .dir-rtl .landing .container .info .text {
    margin-left: unset;
  }
}

.landing h3 {
  color: #fd5b00;
  font-weight: 700;
}

.landing h3 svg {
  position: relative;
  top: 10px;
}

.landing .text h1 {
  font-size: 80px;
  margin: 0;
  font-weight: 700;
}

@media screen and (max-width: 1425px) {
  .landing .text h1 {
    font-size: 70px;
    margin: 0;
    font-weight: 700;
  }
}

@media screen and (max-width: 1325px) {
  .landing .text h1 {
    font-size: 80px;
  }
}
@media screen and (max-width: 580px) {
  .landing .text h1 {
    font-size: 50px;
  }
}

.dir-rtl .landing .text h1 {
  margin-bottom: 26px;
}

.landing .text p {
  font-size: 17px;
  line-height: var(--line-height);
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(116, 116, 116, 0.2);
  /* font-family: var(--en-medium); */
}

.landing .container .btns {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 16px;
  justify-content: space-between;
  align-items: center;
}

.landing .container .btns .links-btns {
  display: flex;
  gap: 35px;
}
@media screen and (max-width: 1100px) {
  .landing .container .btns {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 650px) {
  .landing .container .btns .links-btns {
    gap: 16px;
  }
  .landing .container .btns .links-btns a {
    width: 190px !important;
  }
  .landing .container .info .text {
    margin-top: 120px !important;
  }
  .landing .container .info .text,
  .landing h3 {
    width: 100%;
    text-align: start !important;
  }
  .landing .container .info .text {
    gap: 30px !important;
  }
}

@media screen and (max-width: 420px) {
  .landing .container .btns .links-btns {
    flex-direction: column;
  }
  .landing .container .btns .links-btns a {
    width: 100% !important;
  }
}

.landing .container .btns .work {
  background-color: var(--btn-work);
  color: white;
  width: 275px;
  border-radius: calc(var(--main-radius) + 21px);
  -webkit-border-radius: calc(var(--main-radius) + 21px);
  -moz-border-radius: calc(var(--main-radius) + 21px);
  -ms-border-radius: calc(var(--main-radius) + 21px);
  -o-border-radius: calc(var(--main-radius) + 21px);
}

.landing .container .btns .work svg path {
  fill: white !important;
}
.light-mode .landing .container .btns .work,
.light-mode .landing .container .btns .work svg path {
  color: #2b2f4e;
  fill: #2b2f4e !important;
}

.landing .container .btns a {
  position: relative;
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}
.dir-rtl .landing .container .btns a {
  direction: ltr !important;
}

.landing .container .btns svg path {
  fill: var(--svg-btn-color);
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

.landing .container .btns .work svg path {
  fill: white;
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

.landing .container .btns a:hover svg path {
  fill: white;
}

.landing .container .btns a.contact {
  border: 2px solid var(--btn-bg);
  color: var(--svg-btn-color);
  width: 275px;
  border-radius: calc(var(--main-radius) + 21px);
  -webkit-border-radius: calc(var(--main-radius) + 21px);
  -moz-border-radius: calc(var(--main-radius) + 21px);
  -ms-border-radius: calc(var(--main-radius) + 21px);
  -o-border-radius: calc(var(--main-radius) + 21px);
}

.landing .container .btns a.contact:hover {
  background-color: #b74200;
  border-color: #b74200;
}
.landing .container .btns a.contact:active::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #722900;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  z-index: -1;
  top: 0;
  left: 0;
}

.landing .container .btns a:hover svg path {
  fill: white;
}
.landing .container .btns .social-media-btns {
  display: flex;
  align-items: center;
  gap: 15px;
}
.landing .container .btns .social-media-btns a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  width: 60px;
  height: 60px;
  background-color: #74747433;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.light-mode .landing .container .btns .social-media-btns a {
  background-color: rgba(0, 0, 0, 0.2);
}

/* About */

.container.about .main-heading p {
  font-size: 20px;
  line-height: var(--line-height);
}

.booking-text p {
  color: #9f9f9f;
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  line-height: 139%; /* 38.92px */
  margin-bottom: 24px;
  margin-top: 100px;
}

.booking-text span {
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  line-height: 139%;
  text-decoration-line: underline;
}
.container.about .about-box {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 30px;
  text-align: start;
}
.container.about .about-box .about-left,
.container.about .about-box .about-right {
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-radius: var(--main-radius);
  -webkit-border-radius: var(--main-radius);
  -moz-border-radius: var(--main-radius);
  -ms-border-radius: var(--main-radius);
  -o-border-radius: var(--main-radius);
}

.container.about .about-box .about-right {
  flex: 1;
  background-color: var(--about-bg);
}
.container.about .about-box .about-right .text {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 40px 54px 40px;
}
.container.about .about-box .about-right .text h3 {
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--text-white);
  font-size: 32px;
}

.container.about .about-box .about-right .text p {
  color: #657dad;
}
.container.about .about-box .about-right .text a {
  width: 353px;
  height: 55px;
  padding: 18px 16px;
  align-self: flex-end;
  text-align: center;
  color: white;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 45px;
  background: #fd5b00;
}
@media screen and (max-width: 930px) {
  .container.about .about-box {
    flex-direction: column-reverse;
  }
}
@media screen and (max-width: 500px) {
  .container.about .about-box .about-right .text h3 {
    font-size: 24px;
  }
}
.container.about .about-box .about-right .img img {
  flex: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-top-left-radius: var(--main-radius);
  border-top-right-radius: var(--main-radius);
}

.container.about .about-box .box {
  background-color: var(--about-bg);
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
  flex: 1;
  border-radius: var(--main-radius);
  -webkit-border-radius: var(--main-radius);
  -moz-border-radius: var(--main-radius);
  -ms-border-radius: var(--main-radius);
  -o-border-radius: var(--main-radius);
}

.container.about .about-box .box img {
  max-width: 60px;
}

.container.about .about-box .box .top-side {
  display: flex;
  flex-direction: column;
  gap: 45px;
  align-items: flex-start;
}

.container.about .about-box .box h3 {
  color: var(--text-white);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}
.container.about .about-box .box p {
  color: #657dad;
}
.container.about .about-box .box .box-btn {
  display: flex;
  width: 200px;
  height: 55px;
  padding: 18px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: flex-end;
  color: var(--text-white);
  background-color: var(--about-btn-bg);
  border-radius: calc(var(--main-radius) + 21px);
  -webkit-border-radius: calc(var(--main-radius) + 21px);
  -moz-border-radius: calc(var(--main-radius) + 21px);
  -ms-border-radius: calc(var(--main-radius) + 21px);
  -o-border-radius: calc(var(--main-radius) + 21px);
}
.container.about .about-box .box .box-btn svg path {
  fill: var(--text-white);
}
.container.about
  .container.about
  .about-box
  .box
  a
  .container.about
  .about-box
  .box
  .container.about
  .about-box
  .about-right
  .img
  img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 16/9;
  object-position: center;
  overflow: hidden;
  border-top-left-radius: var(--main-radius);
  border-top-right-radius: var(--main-radius);
}
.container.about .about-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 24px;
}

.dir-rtl .container.about .about-btns {
  flex-direction: row-reverse;
}

@media screen and (max-width: 520px) {
  .container.about .about-btns,
  .dir-rtl .container.about .about-btns {
    flex-direction: column-reverse;
  }
}

.container.about .about-btns .book-btn:hover {
  background: #b74200;
  border-color: #b74200;
}
.container.about .about-btns .book-btn:active,
.landing .container .btns a:first-of-type:active {
  background: #722900;
  border-color: #722900;
}

.container.about .about-btns a:first-of-type:hover {
  border-color: #b74200;
  color: #b74200;
}
.container.about .about-btns a:first-of-type:hover svg path {
  fill: #b74200;
}

.container.about .about-btns a:first-of-type:active {
  border-color: #722900;
  color: #722900;
}
.container.about .about-btns a:first-of-type:active svg path {
  fill: #722900;
}

.container.about .about-btns .book-btn {
  display: flex;
  width: 280px;
  height: 65px;
  padding: 18px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: #fd5b00;
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
  color: white;
}

.dir-rtl .container.about a {
  direction: ltr !important;
  font-family: var(--ar-regular);
}

@media screen and (max-width: 650px) {
  .container.about a {
    width: calc(50% - 24px) !important;
    margin: unset;
  }
}

@media screen and (max-width: 520px) {
  .container.about a {
    width: 100% !important;
  }
}

/* @media screen and (max-width: 420px) {
    .container.about a {
        width: 280px;
    }
} */

.container.about a:hover {
  background-color: transparent;
  color: var(--main-color);
}

.container.about a svg path {
  fill: #fd5b00;
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

/* Services */
#services {
  position: relative;
}

.serv-circles {
  position: absolute;
  z-index: -10;
}

.dir-rtl .serv-circles {
  left: 0;
}

@media screen and (max-width: 630px) {
  .serv-circles {
    max-width: 80%;
  }
}

.container.services .cards-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 40px;
}

/* @media (max-width: 1630px) {
    .container.services .cards-wrapper {
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }
} */

.container.services .cards-wrapper .card {
  /* font-family: "Grandis Extended"; */
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  min-height: 260px;
  padding: 35px 35px 30px;
  border-radius: 24px;
  /* background: var(--icons-bg); */
  background: var(--serv-bg);
  box-shadow: 1px 1px 1px 0px rgba(82, 82, 82, 0.35) inset;
  backdrop-filter: blur(50px);
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

.light-mode .container.services .cards-wrapper .card {
  box-shadow: none;
  background: var(--serv-bg) !important;
}

.container.services .cards-wrapper .card:hover {
  scale: 102%;
  background: linear-gradient(180deg, #1e1e3c 0%, #0a0c12 100%);
}

.container.services .cards-wrapper .card .top-card {
  display: flex;
  align-items: center;
  gap: 16px;
}

.container.services .cards-wrapper .card .top-card .img {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 18px;
  filter: brightness(1.1) saturate(1.1);
  -webkit-filter: brightness(1.1) saturate(1.1);
  transition: var(--main-transition) ease-in-out;
  -webkit-transition: var(--main-transition) ease-in-out;
  -moz-transition: var(--main-transition) ease-in-out;
  -ms-transition: var(--main-transition) ease-in-out;
  -o-transition: var(--main-transition) ease-in-out;
}
.container.services .cards-wrapper .card:hover .top-card .img {
  filter: brightness(1.2) saturate(1.3);
  -webkit-filter: brightness(1.2) saturate(1.3);
  /* box-shadow: 0 0 20px rgba(30, 63, 250, 0.6), 
              0 0 40px rgba(255, 165, 0, 0.4); Multi-colored glow */
}

.container.services .cards-wrapper .card ul {
  list-style: none;
  width: 100%;
}
.container.services .cards-wrapper .card li {
  color: var(--text-white);
  padding: 15px 0;
  text-transform: capitalize;
}

.container.services .cards-wrapper .card .mid-card {
  width: 100%;
}

.container.services .cards-wrapper .card .mid-card li:not(:last-child) {
  border-bottom: 1px solid var(--border);
  width: 100%;
  display: block;
}

.container.services .cards-wrapper .card .top-card h3 {
  color: var(--text-white);
  font-size: 32px;
  font-weight: 700;
}

.dir-rtl .container.services .cards-wrapper .card .top-card h3 {
  font-weight: 500;
}

@media screen and (max-width: 360px) {
  .container.services .cards-wrapper .card .top-card h3 {
    font-size: 20px !important;
  }

  .container.services .cards-wrapper .card .top-card .img {
    width: 50px;
    height: 50px;
  }
}

.container.services .tip {
  color: var(--text-p-color);
  font-size: 20px;
  margin-top: 17px;
  line-height: var(--line-height);
}
.container.services .tip span {
  color: var(--main-color);
}

.dir-rtl .container.services .cards-wrapper .card .mid-card p {
  font-family: var(--ar-regular);
}

@media (max-width: 1400px) {
  .container.services .cards-wrapper .card .mid-card p {
    font-size: 18px;
  }
}

@media (max-width: 1230px) {
  .container.services .cards-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
}

@media (min-width: 991px) and (max-width: 1028px) {
  .container.services .cards-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media screen and (max-width: 430px) {
  .container.services .cards-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .container.services .cards-wrapper .card .top-card h3 {
    font-size: 24px;
  }
}

/* Portfolio */

.container.portfolio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}
.container.portfolio .main-heading h1 {
  position: relative;
  align-self: center;
}
.container.portfolio .smallStar {
  position: absolute;
  top: -10px;
  left: -25px;
}
.dir-rtl .container.portfolio .smallStar {
  position: absolute;
  top: -10px;
  right: -15px;
}

.container.portfolio .main-heading {
  color: #fd5b00;
  font-size: 45px;
  font-weight: 800;
  text-align: center;
  font-family: var(--ar-bold);
  display: flex;
  flex-direction: column;
}

.container.portfolio .main-heading p {
  color: #9f9f9f;
  font-size: 14px;
}

.container.portfolio ul {
  display: flex;
  padding: 10px 12px;
  margin-bottom: 24px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  border-radius: 9999px;
  background: var(--filter-bg);
  box-shadow: 1px 1px 0px 0px rgba(255, 255, 255, 0.35) inset;
  backdrop-filter: blur(50px);
}

.container.portfolio ul li {
  position: relative;
  cursor: pointer;
  text-align: center;
}

.container.portfolio ul li a {
  color: var(--text-white);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.dir-rtl .container.portfolio ul li a {
  flex-direction: row-reverse;
}

.dir-rtl .container.portfolio ul li .left-arrow {
  transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  -o-transform: scaleX(-1);
}

.dir-rtl .container.portfolio ul li .right-arrow {
  transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  -o-transform: scaleX(-1);
}

@media screen and (max-width: 1100px) {
  .container.portfolio ul {
    width: 750px;
  }

  .container.portfolio ul li a {
    font-size: 14px;
  }

  .container.portfolio ul li .left-arrow {
    padding: 20px 30px 20px 20px !important;
  }

  .container.portfolio ul li .right-arrow {
    padding: 20px 20px 20px 30px !important;
  }
}

@media screen and (max-width: 768px) {
  .container.portfolio ul {
    width: 100%;
  }

  .container.portfolio ul {
    gap: 20px;
    justify-content: space-between;
  }

  .container.portfolio ul li a {
    font-size: 20px;
  }

  .container.portfolio ul li svg {
    display: none;
  }
}

@media screen and (max-width: 690px) {
  .container.portfolio ul li .left-arrow {
    padding: 20px 60px 20px 20px !important;
  }

  .container.portfolio ul li .right-arrow {
    padding: 20px 40px 20px 60px !important;
  }

  .container.portfolio ul li a {
    font-size: 16px;
  }
}

@media screen and (max-width: 650px) {
  .container.portfolio ul {
    gap: 15px;
  }

  .container.portfolio ul li .left-arrow {
    padding: 20px 50px 20px 20px !important;
  }

  .container.portfolio ul li .right-arrow {
    padding: 20px 20px 20px 50px !important;
  }
}

@media screen and (max-width: 577px) {
  .container.portfolio ul li .left-arrow {
    padding: 20px 30px 20px 20px !important;
  }

  .container.portfolio ul li .right-arrow {
    padding: 20px 20px 20px 30px !important;
  }
}

@media screen and (min-width: 540px) {
  .container.portfolio ul li .link-small {
    display: none;
  }
}

@media screen and (max-width: 540px) {
  .container.portfolio ul li:nth-of-type(2):before,
  .container.portfolio ul li:nth-of-type(6):before {
    display: none;
  }

  .container.portfolio ul li .link-large {
    display: none;
  }

  .container.portfolio ul li .link-small {
    display: block;
  }

  .container.portfolio ul li .left-arrow {
    padding: 20px 10px 20px 10px !important;
  }

  .container.portfolio ul li .right-arrow {
    padding: 20px 10px 20px 10px !important;
  }
}

@media screen and (max-width: 460px) {
  .container.portfolio ul li svg {
    display: block;
  }

  .container.portfolio ul li a span {
    display: none !important;
  }
}

@media screen and (max-width: 360px) {
  .container.portfolio ul li .left-arrow {
    width: 34px !important;
  }

  .container.portfolio ul li .right-arrow {
    width: 34px !important;
  }

  .container.portfolio ul li svg {
    width: 15px !important;
  }
}

@media screen and (max-width: 310px) {
  .container.portfolio ul li .left-arrow {
    width: 20px !important;
    padding-right: 0 !important;
  }

  .container.portfolio ul li .right-arrow {
    width: 20px !important;
    padding-left: 0 !important;
  }
}

.container.portfolio ul li a span {
  display: inline-block;
}

.container.portfolio ul li:nth-of-type(2):before,
.container.portfolio ul li:nth-of-type(6):before {
  content: "";
  position: absolute;
  border-left: 2px solid var(--text-white);
  width: 2px;
  height: 40px;
  opacity: 0.6;
  top: 50%;
  translate: 0% -50%;
}

.container.portfolio ul li:nth-of-type(2):before {
  left: -30px;
}

.dir-rtl .container.portfolio ul li:nth-of-type(2):before {
  right: -40px;
}

.container.portfolio ul li:nth-of-type(6):before {
  right: -30px;
}

.dir-rtl .container.portfolio ul li:nth-of-type(6):before {
  right: 110px;
}

.container.portfolio ul li .left-arrow {
  padding: 20px 50px 20px 20px;
}

.container.portfolio ul li .left-arrow.disabled,
.container.portfolio ul li .right-arrow.disabled {
  /* cursor: not-allowed;
    user-select: none; */
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.container.portfolio ul li .right-arrow {
  padding: 20px 20px 20px 50px;
}

.container.portfolio ul li svg {
  width: 24px;
  height: 24px;
}

.container.portfolio ul li svg path {
  fill: var(--text-white);
}

.container.portfolio ul li a.active,
.container.portfolio ul li a.active svg path {
  color: var(--main-color);
  fill: var(--main-color);
}

/* .container.portfolio ul li.active svg {
    width: 28px;
} */

.portfolio-cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-template-rows: repeat(7, 430px);
  gap: 16px;
  direction: ltr !important;
}

@media screen and (max-width: 1520px) {
  .portfolio-cards,
  .certificates {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)) !important;
  }
}

@media screen and (max-width: 1024px) {
  .portfolio-cards,
  .certificates {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)) !important;
  }
}

@media screen and (max-width: 768px) {
  .portfolio-cards {
    grid-template-columns: repeat(1, minmax(200px, 1fr)) !important;
    grid-template-rows: repeat(21, 420px) !important;
  }
}

@media screen and (max-width: 572px) {
  .portfolio-cards {
    grid-template-rows: repeat(21, 330px) !important;
  }
}

.portfolio-card {
  position: relative;
  overflow: hidden;
  border-radius: var(--main-radius);
  -webkit-border-radius: var(--main-radius);
  -moz-border-radius: var(--main-radius);
  -ms-border-radius: var(--main-radius);
  -o-border-radius: var(--main-radius);
}

.portfolio-cards img {
  aspect-ratio: 4/3;
  object-fit: cover;
  object-position: center;
  max-width: 100%;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
}

.portfolio-card video {
  aspect-ratio: 16/9;
  object-fit: fill;
  object-position: center;
  max-width: 100%;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
}

.portfolio-cards .portfolio-card .portfolio-layer {
  opacity: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;
  padding: 20px;
  bottom: 0;
  left: 0;
  width: 105%;
  height: 100%;
  border-radius: 24px;
  background: rgba(0, 0, 0, 0.44);
  box-shadow: 1px 1px 1px 0px rgba(82, 82, 82, 0.35) inset;
  backdrop-filter: blur(2px);
  color: var(--bg-white);
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

.portfolio-cards .portfolio-card:hover .portfolio-layer {
  opacity: 1;
}

.portfolio-cards .portfolio-card .portfolio-layer a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  margin-right: 50px;
  width: 50px;
  height: 50px;
  background-color: var(--bg-white);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

.portfolio-cards .portfolio-card .portfolio-layer a:first-of-type {
  margin-left: 12px;
}

.portfolio .portfolio-container .portfolio-box:hover .portfolio-layer {
  opacity: 1;
}

.portfolio-cards .portfolio-card .portfolio-layer p {
  font-size: 32px;
  font-weight: 700;
  margin: 40px 0;
  line-height: 1.8;
  opacity: 0;
  color: white;
  text-align: left;
  transition: 2s;
  -webkit-transition: 2s;
  -moz-transition: 2s;
  -ms-transition: 2s;
  -o-transition: 2s;
}

/* @media screen and (max-width: 768px) {
    .portfolio-cards .portfolio-card .portfolio-layer p {
        font-size: 24px;
    }
} */

@media screen and (max-width: 460px) {
  .portfolio-cards .portfolio-card .portfolio-layer p {
    font-size: 30px;
  }
}

@media screen and (max-width: 368px) {
  .portfolio-cards .portfolio-card .portfolio-layer p {
    font-size: 24px;
  }
}

.portfolio-cards .portfolio-card:hover .portfolio-layer p {
  opacity: 1;
}

.portfolio-cards .portfolio-card .portfolio-layer .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 32px;
  padding: 20px;
  /* margin-top: 0; */
  position: absolute;
  /* bottom: -50%; */
  scale: 0;
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

@media screen and (max-width: 768px) {
  .portfolio-cards .portfolio-card .portfolio-layer .icon {
    padding: 12px;
  }

  .portfolio-cards .portfolio-card .portfolio-layer .icon svg {
    width: 28px;
    height: 28px;
  }

  .portfolio-cards .portfolio-card .portfolio-layer a {
    margin-right: 20px !important;
  }

  .portfolio-cards .portfolio-card .portfolio-layer a {
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 460px) {
  .portfolio-cards .portfolio-card .portfolio-layer .icon {
    padding: 10px;
  }
}

.portfolio-cards .portfolio-card:hover .portfolio-layer .icon:nth-of-type(1) {
  animation: grow 0.4s linear both;
  -webkit-animation: grow 0.4s linear both;
}

/* .portfolio-cards .portfolio-card:hover .portfolio-layer .delay {
    animation: grow 0.5s 0.5s linear both;
    -webkit-animation: grow 0.5s 0.5s linear both;
} */

.portfolio-cards .portfolio-card .portfolio-layer .icon:hover {
  background-color: var(--main-color);
}
.portfolio .behance-btns {
  display: flex;
  gap: 25px;
  margin-top: 25px;
}
.portfolio .main-btn.behance {
  width: 300px !important;
  font-size: 16px !important;
  border-radius: calc(var(--main-radius) + 21px);
  -webkit-border-radius: calc(var(--main-radius) + 21px);
  -moz-border-radius: calc(var(--main-radius) + 21px);
  -ms-border-radius: calc(var(--main-radius) + 21px);
  -o-border-radius: calc(var(--main-radius) + 21px);
}

.portfolio .behance-btns .main-btn.work {
  background-color: var(--workBtn);
  width: 210px;
  color: #b5b5b5;
  border-radius: calc(var(--main-radius) + 21px);
  -webkit-border-radius: calc(var(--main-radius) + 21px);
  -moz-border-radius: calc(var(--main-radius) + 21px);
  -ms-border-radius: calc(var(--main-radius) + 21px);
  -o-border-radius: calc(var(--main-radius) + 21px);
}
.portfolio .behance-btns .main-btn.work svg path {
  fill: #b5b5b5;
}
.light-mode .portfolio .behance-btns .main-btn.work,
.light-mode .portfolio .behance-btns .main-btn.work svg path {
  fill: white;
  color: white;
}
@media screen and (max-width: 590px) {
  .portfolio .behance-btns {
    flex-direction: column;
  }
  .portfolio .behance-btns a {
    width: 100% !important;
  }
}
/* Reviews */

.container.reviews {
  position: relative;
  direction: ltr !important;
}

.container.reviews .circles,
.container.reviews .light {
  position: absolute;
  right: 0;
  z-index: -120;
  max-width: 100%;
}

.container.reviews .circles {
  position: absolute;
  right: 0;
  top: -100%;
  z-index: -1000;
}

.container.reviews .light {
  position: absolute;
  right: 0;
  height: 800px;
  top: -40%;
}

@media screen and (max-width: 542px) {
  .container.reviews .star {
    display: none !important;
  }
}

.swiper-scrollbar {
  display: none;
}

.container.reviews .review-card {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  padding-bottom: 100px;
  margin-bottom: 50px;
  gap: 20px;
  height: 335px;
  padding: 0px 30px 34px 30px;
  flex-shrink: 0;
  background: var(--serv-bg);
  box-shadow: 1px 1px 1px 0px rgba(82, 82, 82, 0.35) inset;
  backdrop-filter: blur(50px);
  border-radius: var(--main-radius);
  -webkit-border-radius: var(--main-radius);
  -moz-border-radius: var(--main-radius);
  -ms-border-radius: var(--main-radius);
  -o-border-radius: var(--main-radius);
}

.dir-rtl .container.reviews .review-card {
  align-items: end;
}
.dir-rtl .container.reviews .review-card .review-card-info {
  flex-direction: row-reverse;
}
.dir-rtl .container.reviews .review-card .review-card-info-text,
.dir-rtl .container.reviews .review-card .review-card-text {
  text-align: right;
}

.light-mode .container.reviews .review-card {
  box-shadow: none;
}

.container.reviews .review-card .review-card-text {
  color: var(--text-white);
  font-size: 32px;
  font-weight: 800;
  line-height: var(--line-height);
  text-transform: capitalize;
  font-family: var(--ar-regular) !important;
  margin: 20px 0 80px;
  font-weight: 300;
}

@media (max-width: 1580px) {
  .container.reviews .review-card .review-card-text {
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  .container.reviews .review-card .review-card-text {
    font-size: 28px !important;
  }
}

@media (max-width: 480px) {
  .container.reviews .review-card .review-card-text {
    font-size: 24px !important;
  }
}

@media (max-width: 372px) {
  .container.reviews .review-card .review-card-text {
    font-size: 20px !important;
  }
}

@media (max-width: 300px) {
  .container.reviews .review-card .review-card-text {
    font-size: 18px !important;
  }
}

.swiper-button-prev {
  left: var(--swiper-navigation-sides-offset, 40%) !important;
}

.swiper-button-next {
  right: var(--swiper-navigation-sides-offset, 40%) !important;
}

@media (max-width: 1024px) {
  .container.reviews .review-card .review-card-text {
    font-size: 24px;
  }

  .swiper-button-prev {
    left: var(--swiper-navigation-sides-offset, 30%) !important;
  }

  .swiper-button-next {
    right: var(--swiper-navigation-sides-offset, 30%) !important;
  }
}

@media (max-width: 1700px) {
  .swiper-button-prev.for-friends,
  .swiper-button-prev.for-reviews {
    left: var(--swiper-navigation-sides-offset, 35%) !important;
  }

  .swiper-button-next.for-friends,
  .swiper-button-next.for-reviews {
    right: var(--swiper-navigation-sides-offset, 35%) !important;
  }
}

@media (max-width: 1366px) {
  .swiper-button-prev.for-blog {
    left: var(--swiper-navigation-sides-offset, 35%) !important;
  }

  .swiper-button-next.for-blog {
    right: var(--swiper-navigation-sides-offset, 35%) !important;
  }
}

@media (max-width: 1280px) {
  .swiper-button-prev.for-friends,
  .swiper-button-prev.for-reviews {
    left: var(--swiper-navigation-sides-offset, 30%) !important;
  }

  .swiper-button-next.for-friends,
  .swiper-button-next.for-reviews {
    right: var(--swiper-navigation-sides-offset, 30%) !important;
  }

  .swiper-button-prev.for-blog {
    left: var(--swiper-navigation-sides-offset, 30%) !important;
  }

  .swiper-button-next.for-blog {
    right: var(--swiper-navigation-sides-offset, 30%) !important;
  }
}

@media (max-width: 900px) {
  .swiper-button-prev.for-reviews {
    left: var(--swiper-navigation-sides-offset, 25%) !important;
  }

  .swiper-button-next.for-reviews {
    right: var(--swiper-navigation-sides-offset, 25%) !important;
  }
}

@media (max-width: 675px) {
  .swiper-button-prev.for-friends,
  .swiper-button-prev.for-reviews {
    left: var(--swiper-navigation-sides-offset, 20%) !important;
  }

  .swiper-button-next.for-friends,
  .swiper-button-next.for-reviews {
    right: var(--swiper-navigation-sides-offset, 20%) !important;
  }
}

@media (max-width: 592px) {
  .swiper-button-prev.for-blog {
    left: var(--swiper-navigation-sides-offset, 20%) !important;
  }

  .swiper-button-next.for-blog {
    right: var(--swiper-navigation-sides-offset, 20%) !important;
  }
}

@media (max-width: 493px) {
  .swiper-button-prev.for-friends,
  .swiper-button-prev.for-reviews {
    left: var(--swiper-navigation-sides-offset, 15%) !important;
  }

  .swiper-button-next.for-friends,
  .swiper-button-next.for-reviews {
    right: var(--swiper-navigation-sides-offset, 15%) !important;
  }
}

@media (max-width: 450px) {
  .swiper-button-prev {
    left: var(--swiper-navigation-sides-offset, 20%) !important;
  }

  .swiper-button-next {
    right: var(--swiper-navigation-sides-offset, 20%) !important;
  }
}

@media (max-width: 394px) {
  .swiper-button-prev.for-friends,
  .swiper-button-prev.for-reviews {
    left: var(--swiper-navigation-sides-offset, 12%) !important;
  }

  .swiper-button-next.for-friends,
  .swiper-button-next.for-reviews {
    right: var(--swiper-navigation-sides-offset, 12%) !important;
  }
}

@media (max-width: 390px) {
  .swiper-button-prev.for-blog {
    left: var(--swiper-navigation-sides-offset, 10%) !important;
  }

  .swiper-button-next.for-blog {
    right: var(--swiper-navigation-sides-offset, 10%) !important;
  }
}

.container.reviews .review-card .review-card-text span {
  color: var(--main-color);
}

.container.reviews .review-card .review-card-info {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 14px;
  position: absolute;
  bottom: 30px;
  /* flex-direction: row-reverse; */
}

.container.reviews .review-card .review-card-info-img {
  position: relative;
}

.container.reviews .review-card .review-card-info-img img:nth-of-type(2) {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 5px;
  background-color: rgba(49, 221, 99, 1);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.container.reviews .review-card .review-card-info .review-card-info-text h4 {
  color: var(--main-color);
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 14px;
  font-family: var(--ar-regular);
}

.container.reviews .review-card .review-card-info .review-card-info-text p {
  color: #b1b1b1;
  font-size: 14px;
  font-weight: 700;
  font-family: var(--ar-regular);
}

.swiper,
.swiper-container {
  min-height: 500px;
  direction: ltr;
}

@media screen and (max-width: 572px) {
  .swiper,
  .swiper-container {
    min-height: 0;
  }
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: "" !important;
}

.reviews.slider-controler {
  position: relative;
}

.review-left-arrow,
.review-right-arrow {
  position: absolute;
  bottom: 20px;
}

.review-left-arrow path,
.review-right-arrow path {
  fill: var(--arrow-color);
}

.swiper-pagination-bullet {
  background-color: #181929 !important;
}

.swiper-pagination-bullet-active {
  background-color: var(--text-white) !important;
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
  left: 50% !important;
  translate: -50% !important;
  width: fit-content !important;
  bottom: 0px !important;
}

/* Certificates */

.container.reviews {
  width: 100%;
}
.certificates {
  display: flex;
  justify-content: space-between;
  color: var(--text-white);
  background-color: var(--serv-bg);
  padding: 50px;
  border-radius: var(--main-radius);
  -webkit-border-radius: var(--main-radius);
  -moz-border-radius: var(--main-radius);
  -ms-border-radius: var(--main-radius);
  -o-border-radius: var(--main-radius);
}
.dir-rtl .left-side-certificates,
.dir-rtl .right-side-certificates {
  direction: rtl;
}

.dir-rtl .left-side-certificates {
  padding-right: 50px;
}

.certificates .certificate svg {
  width: 60px;
  height: 60px;
  padding: 10px;
  background-color: var(--crtf-bg);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.certificates .left-side-certificates {
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: space-between;
  align-items: center;
  flex-basis: 50%;
  align-items: flex-start;
  border-right: 1px solid var(--border);
}
@media screen and (max-width: 1120px) {
  .certificates {
    flex-direction: column;
  }
  .certificates .left-side-certificates {
    border-right: none;
    padding-bottom: 25px;
    padding-right: 0;
    border-bottom: 1px solid var(--border);
  }
  .certificates .right-side-certificates {
    padding-left: 0 !important;
  }
}

.certificates .left-side-certificates li,
ul li .text {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
}
.certificates .left-side-certificates li .text {
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}
.certificates .left-side-certificates li .text p {
  color: var(--crtf-text-color);
}
.certificates .left-side-certificates li .text > :first-child {
  border-bottom: 1px solid var(--border);
  padding-bottom: 20px;
  width: 100%;
}
.certificates .right-side-certificates {
  flex: 1;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.certificates .right-side-certificates ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  align-items: self-start;
}
.certificates .right-side-certificates ul li {
  padding: 20px;
  background-color: var(--crtf-bg);
  text-transform: capitalize;
  border-radius: var(--main-radius);
  -webkit-border-radius: var(--main-radius);
  -moz-border-radius: var(--main-radius);
  -ms-border-radius: var(--main-radius);
  -o-border-radius: var(--main-radius);
}
.certificates .right-side-certificates a {
  margin-top: 25px;
}

/* Friends */

.container.friends {
  position: relative;
}
.dir-rtl .container.friends {
  direction: ltr !important;
}

.container.friends .friends-sun {
  position: absolute;
  left: 0;
  height: 622px;
}

@media screen and (max-width: 380px) {
  .container.friends .main-heading h2 {
    font-size: 24px !important;
  }
}

.container.friends .friend-card {
  position: relative;
  margin-bottom: 70px;
  border-radius: var(--main-radius);
  -webkit-border-radius: var(--main-radius);
  -moz-border-radius: var(--main-radius);
  -ms-border-radius: var(--main-radius);
  -o-border-radius: var(--main-radius);
  overflow: hidden;
}

.container.friends .friend-card .friend-card-info {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 14px;
  min-height: 450px;
  /* flex-direction: row-reverse; */
}
.container.friends .friend-card .friend-card-info-img {
  min-height: 450px;
}

.friends.container .friend-card img {
  aspect-ratio: 16/9;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  min-height: 450px;
}

.friends.container .friend-card .friend-card-info-text-layer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  position: absolute;
  text-align: left;
  width: 100%;
  height: 100%;
  padding: 25px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    #000 100%,
    #0e0c15 100%
  );
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

.dir-rtl .friends.container .friend-card .friend-card-info-text-layer {
  align-items: flex-end;
}

.friends.container .friend-card .friend-card-info-text-layer h2 {
  color: var(--main-color);
  font-size: 20px;
}

.friends.container .friend-card .friend-card-info-text-layer p {
  color: #fff;
  font-size: 11px;
  line-height: var(--line-height);
  margin: 10px 0 20px;
  overflow: hidden;
}
.dir-rtl .friends.container .friend-card .friend-card-info-text-layer p {
  text-align: right;
}

@media screen and (max-width: 572px) {
  .friends.container .friend-card .friend-card-info-text-layer h2 {
    font-size: 12px;
  }

  .friends.container .friend-card .friend-card-info-text-layer p {
    font-size: 8px;
    max-height: 45px;
  }
}

.friends.container
  .friend-card
  .friend-card-info
  .friend-card-info-text-layer-links
  a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  width: 50px;
  height: 50px;
  color: white;
  cursor: pointer;
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

.friends.container
  .friend-card
  .friend-card-info
  .friend-card-info-text-layer-links
  .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  padding: 14px;
  cursor: pointer;
  /* scale: 0; */
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

/* .friends.container .friend-card:hover .friend-card-info .friend-card-info-text-layer-links .icon {
    animation: grow 0.4s linear both;
    -webkit-animation: grow 0.4s linear both;
} */

.friends.container
  .friend-card
  .friend-card-info
  .friend-card-info-text-layer-links
  .icon:hover {
  background-color: var(--main-color);
}

/* .friends.slider-controler {
    position: relative;
    padding-bottom: 80px;
}

.friends.slider-controler .review-left-arrow,
.friends.slider-controler .review-right-arrow {
    bottom: -20px;
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0px !important;
} */

/* Blog */
.blog.container {
  position: relative;
}

.blog.container .blog-left-circles,
.blog.container .blog-right-circles {
  position: absolute;
  z-index: -200;
}

.blog.container .blog-left-circles {
  left: 0;
}

.blog.container .blog-right-circles {
  right: 0;
}

.blog.container .blog-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 35px 50px;
}

@media (max-width: 1590px) {
  .blog.container .blog-cards {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
}

@media (max-width: 900px) {
  .blog.container .blog-cards {
    grid-template-columns: repeat(1, minmax(300px, 1fr));
  }
}

@media screen and (max-width: 390px) {
  .blog.container .blog-cards {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }
}

.blog-card {
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

.dir-rtl .blog-card {
  direction: rtl !important;
}

.blog-card:hover {
  scale: 102%;
}

.blog-card img {
  aspect-ratio: 4/3;
  object-fit: cover;
  object-position: center;
  max-width: 100%;
  width: 100%;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

.blog-card .blog-card-content h2 {
  color: var(--main-color);
  border-radius: 8px;
  background: rgba(253, 137, 0, 0.07);
  width: fit-content;
  height: 43px;
  padding: 7px 15px;
  margin: 14px 0;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog-card .blog-card-content p {
  color: var(--text-white);
  font-size: 24px;
  font-weight: 500;
  line-height: var(--line-height);
  margin-bottom: 40px;
}

.blog.container .blog-btn a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 332px;
  padding: 18px 16px;
  margin: 100px auto;
  border: 2px solid var(--main-color);
  color: white;
  border-radius: 16px;
  background: var(--main-color);
  cursor: pointer;
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

@media screen and (max-width: 390px) {
  .blog.container .blog-btn a {
    width: 250px;
  }
}

@media screen and (max-width: 300px) {
  .blog.container .blog-btn a {
    width: 200px;
  }
}

.blog.container .blog-btn a:hover {
  background-color: transparent;
  color: var(--main-color);
}

/* Contact */
.contact.container {
  position: relative;
}

.contact.container .main-heading {
  margin-bottom: 150px;
}

.contact.container .contact-left-circles,
.contact.container .contact-right-circles {
  position: absolute;
  top: 20%;
  z-index: -100;
}

.contact.container .contact-left-circles {
  left: 0;
}

.contact.container .contact-right-circles {
  right: 0;
}

.contact.container .contact-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px;
}

@media screen and (max-width: 1230px) {
  .contact.container .contact-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .contact.container .contact-content {
    min-width: 100% !important;
  }

  .contact.container .contact-content .contact-form form {
    min-width: 100% !important;
  }
}

.contact.container .contact-content .contact-info-text {
  display: flex;
  align-items: center;
}

.contact.container .contact-content .contact-info-text:not(:last-of-type) {
  margin-bottom: 20px;
}

.contact.container .contact-content .contact-info-text .icon {
  display: inline-flex;
  padding: 16px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  background: var(--social-media-icon-bg);
}

.contact.container .contact-content .contact-info-text p {
  margin-left: 20px;
  color: var(--text-white);
}

.dir-rtl .contact.container .contact-content .contact-info-text p {
  margin-right: 20px;
}

.contact.container .contact-content .contact-form form {
  display: flex;
  flex-direction: column;
  min-width: 620px;
  gap: 20px;
}

@media screen and (max-width: 703px) {
  .contact.container .contact-content .contact-form .text-inputs .name-input,
  .contact.container
    .contact-content
    .contact-form
    .text-inputs
    .calling-input {
    width: 100%;
  }
}

@media screen and (max-width: 692px) {
  .contact.container .contact-content .contact-form form {
    min-width: 200px;
  }
}

.contact.container .contact-content .contact-form form .relative {
  position: relative;
}

@media screen and (min-width: 704px) {
  .contact.container .contact-content .contact-form form .relative {
    flex: 1;
  }
}

.contact.container .contact-content .contact-form .text-inputs {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.contact.container .contact-content .contact-form input,
.contact.container .contact-content .contact-form textarea {
  caret-color: var(--main-color);
  border-color: transparent;
  outline: none;
  color: var(--text-white);
  padding: 20px 50px !important;
  width: 100%;
  height: 60px;
}

.contact.container .contact-content .contact-form input:focus,
.contact.container .contact-content .contact-form textarea:focus {
  border-color: var(--main-color);
}

.contact.container .contact-content .contact-form input:focus + label svg path,
.contact.container
  .contact-content
  .contact-form
  textarea:focus
  + label
  svg
  path,
.contact.container
  .contact-content
  .contact-form
  select:focus
  + label
  svg
  path {
  fill: var(--main-color);
}

.contact.container .contact-content .contact-form input[type="text"] {
  display: flex;
  align-items: center;
  border-radius: 12px;
  background: var(--contact-bg) !important;
}

.contact.container .contact-content .contact-form .submit-btn {
  direction: ltr !important;
}

.contact.container .contact-content .contact-form svg {
  position: absolute;
  left: 30px;
  top: 50%;
  translate: -50% -50%;
}

.dir-rtl .contact.container .contact-content .contact-form svg {
  left: auto;
  right: 10px;
}

.contact.container .contact-content .contact-form svg path {
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

.contact.container .contact-content .contact-form svg.message {
  position: absolute;
  left: 30px;
  top: 28px;
}

.contact.container .contact-content .contact-form svg.send {
  position: absolute;
  left: 66%;
  cursor: pointer;
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

@media screen and (max-width: 692px) {
  .contact.container .contact-content .contact-form svg.send {
    left: 70%;
  }
}

@media screen and (max-width: 560px) {
  .contact.container .contact-content .contact-form svg.send {
    left: 74%;
  }
}

@media screen and (max-width: 480px) {
  .contact.container .contact-content .contact-form svg.send {
    left: 78%;
  }
}

@media screen and (max-width: 420px) {
  .contact.container .contact-content .contact-form svg.send {
    left: 82%;
  }
}

@media screen and (max-width: 362px) {
  .contact.container .contact-content .contact-form svg.send {
    left: 86%;
  }
}

@media screen and (max-width: 330px) {
  .contact.container .contact-content .contact-form svg.send {
    left: 90%;
  }
}

.contact.container .contact-content .contact-form svg.send path {
  fill: white;
}

.contact.container .contact-content .contact-form svg path {
  fill: rgba(158, 158, 158, 1);
}

.contact.container .contact-content .contact-form .options select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: flex;
  width: 100%;
  height: 60px;
  padding: 20px 50px !important;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 12px;
  background: var(--contact-bg);
  border-radius: 12px;
  background: var(--contact-bg);
  color: rgba(158, 158, 158, 1);
  border-color: transparent;
  outline: none;
  cursor: pointer;
  color: #9e9e9e;
  font-size: 14px;
}

.dir-rtl .contact.container .contact-content .contact-form .options select {
  padding: 20px 70px 20px 20px !important;
}

.contact.container .contact-content .contact-form .options select:focus {
  border-color: var(--main-color);
}

.contact.container .contact-content .contact-form .options .arrow-down-select {
  position: absolute;
  right: 40px;
  top: 50%;
  translate: -50% -50%;
  pointer-events: none;
}

.contact.container .contact-content .contact-form .options select option {
  border: none;
  outline: none;
  padding: 10px;
}

.contact.container .contact-content .contact-form textarea {
  width: 100%;
  height: 250px;
  padding: 20px;
  flex-shrink: 0;
  border-radius: 12px;
  background: var(--contact-bg);
  resize: none;
}

.contact.container .contact-content .contact-form input[type="submit"] {
  display: flex;
  padding: 18px 16px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 2px solid var(--main-color);
  border-radius: 16px;
  background: var(--main-color);
  text-transform: capitalize;
  font-size: 18px;
  cursor: pointer;
  color: white;
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

.contact.container .contact-content .contact-form input[type="submit"]:focus {
  border: 2px solid white !important;
}

.contact.container .contact-content .contact-form input[type="submit"]:hover {
  background-color: transparent;
  color: var(--main-color);
}

.contact.container
  .contact-content
  .contact-form
  input[type="submit"]:hover
  + .icon
  .send
  path {
  fill: var(--main-color);
}

.contact.container .contact-content .sent-succes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: center;
  /* padding: 50px 0; */
}

.contact.container .contact-content .sent-succes img {
  width: 75%;
}

.contact.container .contact-content .sent-succes .heading {
  font-size: 40px;
  color: var(--text-white);
}

.contact.container .contact-content .sent-succes .paragraph {
  max-width: 600px;
  color: var(--text-p-color);
  line-height: var(--line-height);
  margin: 20px 0;
}

.contact.container .contact-content .sent-succes .btns,
.no-results-container .btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  gap: 30px;
  direction: ltr !important;
}

@media screen and (max-width: 460px) {
  .no-results-container .btns {
    margin-top: 80px;
  }
}
@media screen and (max-width: 340px) {
  .no-results-container .btns {
    margin-top: 120px;
  }
}

.contact.container .contact-content .sent-succes .btns a,
.contact.container .contact-content .sent-succes .btns button,
.no-results-container .btns a {
  width: 175px;
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

.contact.container .contact-content .sent-succes .btns a.home,
.no-results-container .btns a.home {
  background-color: var(--main-color);
  border: 2px solid var(--main-color);
}

.light-mode .contact.container .contact-content .sent-succes .btns a.home:hover,
.light-mode .no-results-container .btns a.home:hover {
  background-color: transparent;
  color: #181929;
}

.contact.container .contact-content .sent-succes .btns a.home:hover,
.no-results-container .btns a.home:hover {
  background-color: transparent;
  color: white;
}

.contact.container .contact-content .sent-succes .btns .new,
.no-results-container .btns .new {
  background-color: transparent;
  border: 2px solid #a5a5a5;
  color: #a5a5a5;
  cursor: pointer;
}

.social-media {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin: 70px 0;
  gap: 25px;
}

.social-media .social-media-card a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  color: var(--text-white);
  font-size: 20px;
  /* padding: 88.4px; */
  /* width: 306px; */
  height: 150px;
  background: var(--scm-bg);
  border-radius: 18px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  -ms-border-radius: 18px;
  -o-border-radius: 18px;
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

@media screen and (max-width: 360px) {
  .social-media {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

.social-media .social-media-card a svg path {
  fill: var(--text-white);
}

.social-media .social-media-card a:hover {
  background-color: var(--main-color);
}

/* Footer */
footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--bg-color);
  border-top: 2px solid var(--footer-border);
  width: 100%;
}

footer .moon,
footer .sun {
  position: absolute;
}

footer .moon {
  left: 0;
}

footer .sun {
  right: 0;
}

footer .footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  position: relative;
  z-index: 100;
}

footer .footer-content .description {
  width: 55%;
  margin: 60px auto;
  text-align: center;
  color: #8c8c8c;
  font-size: 14px;
  line-height: var(--line-height);
}

footer .footer-content .links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;
}

@media screen and (max-width: 572px) {
  footer .footer-content .links {
    flex-direction: column;
    gap: 50px;
  }
}

footer .footer-content .links li a {
  color: #8c8c8c;
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

footer .footer-content .links li a:hover {
  color: var(--main-color);
}

footer .footer-content .footer-content-text {
  color: #515151;
  font-size: 18px;
}

@media screen and (max-width: 572px) {
  footer .footer-content .footer-content-text {
    text-align: center;
  }
}

/* Contact Page */

header + .container.contact {
  margin-top: 160px;
}

header + .sent-succes {
  margin-top: 180px;
  margin-bottom: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

header + .sent-succes .heading {
  font-size: 40px;
  color: var(--text-white);
}

header + .sent-succes .paragraph {
  max-width: 600px;
  color: var(--text-p-color);
  line-height: var(--line-height);
  margin: 20px 0;
  text-align: center;
}

header + .sent-succes .btns {
  max-width: 600px;
  color: var(--text-p-color);
  line-height: var(--line-height);
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

header + .sent-succes .btns a,
header + .sent-succes .btns button {
  width: 175px;
  height: 58.39px;
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

header + .sent-succes .btns a.home {
  background-color: var(--main-color);
  border: 2px solid var(--main-color);
}

header + .sent-succes .btns a.home:hover {
  background-color: transparent;
  color: #181929;
}

header + .sent-succes .btns a.home:hover {
  background-color: transparent;
  color: white;
}

header + .sent-succes .btns .new,
.no-results-container .btns .new {
  background-color: transparent;
  border: 2px solid #a5a5a5;
  color: #a5a5a5;
  cursor: pointer;
}

/* Blog Page */

header + .container.blog {
  margin-top: 250px;
}

header + .container.blog .search {
  position: relative;
  width: 100%;
  padding: 30px 100px;
  border-radius: 12px;
  background: var(--contact-bg);
  caret-color: var(--main-color);
  display: flex;
}

@media screen and (max-width: 400px) {
  header + .container.blog .search {
    padding: 30px 50px;
  }

  header + .container.blog .search svg {
    left: 20px !important;
  }
}

@media screen and (max-width: 390px) {
  header + .container.blog .main-heading h2 {
    font-size: 40px !important;
  }

  header + .container.blog .main-heading h2 img {
    width: 40px;
  }
}

@media screen and (max-width: 300px) {
  header + .container.blog .search {
    padding: 30px 20px;
  }

  header + .container.blog .search svg {
    display: none;
  }
}

/* header+.container.blog .search::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 50%;
    background-color: var(--searchAfterElement);
    right: 130px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
} */

@media screen and (max-width: 460px) {
  header + .container.blog .search::after {
    content: none;
  }

  header + .container.blog .search input[type="submit"] {
    padding: 15px !important;
  }
}

@media screen and (max-width: 390px) {
  header + .container.blog .search::after {
    content: none;
  }

  header + .container.blog .search input[type="submit"] {
    padding: 10px !important;
  }

  header + .container.blog .search ::placeholder {
    font-size: 14px;
  }
}

header + .container.blog .search input[type="text"] {
  flex: 1;
  border: none;
  outline: none;
  background: transparent;
  color: var(--text-white);
}

header + .container.blog .search input[type="submit"] {
  padding: 30px;
  color: var(--main-color);
  border: none;
  outline: none;
  background-color: transparent;
  position: absolute;
  right: 5px;
  top: 50%;
  translate: 0 -50%;
  cursor: pointer;
}

header + .container.blog .search svg {
  position: absolute;
  top: 50%;
  left: 50px;
  translate: -50% -50%;
}

.dir-rtl header + .container.blog .search svg {
  right: 50px;
}

.light-mode header + .container.blog .search svg path {
  fill: #c4c4c4;
}

header + .container.blog .main-heading h2,
header + .container.blog .main-heading p {
  text-align: left;
}
.dir-rtl header + .container.blog .main-heading h2,
.dir-rtl header + .container.blog .main-heading p {
  text-align: right;
}

header + .container.blog .main-heading h2 {
  font-size: 70px;
}

header + .container.blog .main-heading p {
  margin: 20px 0;
  line-height: var(--line-height);
}

/* header + .container.blog .no-results-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

header + .container.blog .no-results-container img {
  width: 500px;
}

header + .container.blog .no-results {
  margin: 80px 0;
  font-size: 40px;
  text-align: center;
  color: var(--text-p-color);
} */
.no-results-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.no-results-container img {
  max-width: 500px;
  width: 100%;
  padding: 0 24px;
}

.no-results-container.loading {
  margin: 80px 0;
  font-size: 40px;
  text-align: center;
  color: var(--text-p-color);
}

.no-results {
  margin: 80px 0;
  font-size: 40px;
  text-align: center;
  color: var(--text-p-color);
  max-width: 70%;
}

header + .no-results-container {
  position: relative;
  padding: 100px 24px;
  overflow: hidden;
}
header + .no-results-container .contact-left-circles {
  position: absolute;
  left: 0;
  top: 10%;
  z-index: -100;
}
header + .no-results-container .contact-right-circles {
  position: absolute;
  right: 0;
  top: 10%;
  z-index: -101;
}

@media screen and (max-width: 991px) {
  header + .no-results-container .contact-left-circles {
    display: none;
  }
  header + .no-results-container {
    overflow: unset;
  }
}

header + .no-results-container .no-results-heading {
  color: var(--text-white);
  text-align: center;
  font-size: 40px;
  font-weight: 750;
  line-height: 120%; /* 48px */
}

header + .no-results-container .no-results-paragraph {
  max-width: 630px;
  height: 72px;
  color: var(--text-p-color);
  text-align: center;
  font-size: 16px;
  line-height: 22.24px;
}

header + .container.blog .blog-cards {
  margin: 150px 0;
}

header + .container.blog .blog-filter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
}

header + .container.blog .blog-filter li {
  padding: 7px 15px;
  border-radius: 8px;
  background: rgba(253, 137, 0, 0.07);
  color: var(--main-color);
  cursor: pointer;
  position: relative;
}

header + .container.blog .blog-filter li span {
  position: absolute;
  bottom: -50px;
  left: 50%;
  translate: -50%;
  padding: 7px 15px;
  background: rgba(253, 137, 0, 0.07);
  border-radius: 8px;
}

header + .container.blog .keywords {
  margin-top: 20px;
  color: var(--text-p-color);
  text-transform: uppercase;
}

header + .container.blog .keywords span {
  text-decoration: underline !important;
}

header + .container.blog .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-bottom: 100px;
}

.dir-rtl
  header
  + .container.blog
  .pagination
  li:where(:first-child, :last-child)
  a {
  rotate: 180deg;
}

@media screen and (max-width: 573px) {
  header + .container.blog .pagination {
    gap: 15px !important;
  }
}

@media screen and (max-width: 490px) {
  header + .container.blog .pagination {
    gap: 10px !important;
  }
}

header + .container.blog .pagination li a {
  color: var(--text-p-color);
  cursor: pointer;
  padding: 20px;
  font-size: 28px;
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

@media screen and (max-width: 490px) {
  header + .container.blog .pagination li a {
    font-size: 20px;
    padding: 15px 20px;
  }

  header + .container.blog .pagination li:where(:first-child, :last-child) a {
    padding: 18px 20px !important;
  }
}

@media screen and (max-width: 400px) {
  header + .container.blog .pagination li a {
    padding: 8px 15px;
  }

  header + .container.blog .pagination li:where(:first-child, :last-child) a {
    padding: 11px 13px !important;
  }
}

@media screen and (max-width: 350px) {
  header + .container.blog .pagination li a {
    padding: 5px 10px;
  }

  header + .container.blog .pagination li:where(:first-child, :last-child) a {
    padding: 9px 11px !important;
  }
}

header + .container.blog .pagination li.active a {
  color: var(--main-color);
}

header + .container.blog .pagination li a:hover {
  background-color: var(--main-color);
  color: white;
}

header + .container.blog .pagination li:where(:first-child, :last-child) a {
  padding: 28px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--main-color);
  fill: white;
  background-color: var(--main-color);
  font-size: 50px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

header + .container.blog .pagination li:first-of-type a svg path,
header + .container.blog .pagination li:last-of-type a svg path {
  fill: white;
  background-color: var(--main-color);
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

header + .container.blog .pagination li:first-of-type.disabled a svg path,
header + .container.blog .pagination li:last-of-type.disabled a svg path {
  fill: var(--main-color);
}

header
  + .container.blog
  .pagination
  li:where(:first-of-type.disabled, :last-of-type.disabled)
  a {
  cursor: not-allowed;
  padding: 28px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--main-color);
  background-color: var(--scm-bg);
  font-size: 50px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

header + .container.blog .pagination li:first-of-type a:hover,
header + .container.blog .pagination li:last-of-type a:hover {
  background-color: var(--scm-bg);
}

header + .container.blog .pagination li:first-of-type a:hover svg path,
header + .container.blog .pagination li:last-of-type a:hover svg path {
  fill: white;
}

.light-mode
  header
  + .container.blog
  .pagination
  li:first-of-type
  a:hover
  svg
  path,
.light-mode
  header
  + .container.blog
  .pagination
  li:last-of-type
  a:hover
  svg
  path {
  fill: var(--text-p-color);
}

/* Blog Details Page */

header + .blog-content {
  margin: 120px 0;
}

/* header + .blog-content .blog-content-img */
header + .blog-content img:not(.date img, .publisher-img img, .blog-body img) {
  aspect-ratio: 4/3;
  width: 100%;
  max-height: 370px !important;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.blog-content.container .blog-img {
  position: relative;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.blog-content.container .blog-img-shadow {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  position: absolute;
  text-align: left;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 25px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    #000 100%,
    #0e0c15 100%
  );
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

header + .blog-content .blog-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  margin: 30px 0;
}

header + .blog-content .blog-info .publisher {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}

header + .blog-content .blog-info .publisher .publisher-img {
  position: relative;
}

header + .blog-content .blog-info .publisher .img-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

header + .blog-content .blog-info .publisher .img-container .profile {
  width: 70px;
}

header
  + .blog-content
  .blog-info
  .publisher
  .img-container
  .publisher-img
  .icon {
  position: absolute;
  left: 60%;
  bottom: 0;
  width: 24px;
}

header + .blog-content .blog-info .publisher .text h3 {
  color: var(--main-color);
}

header + .blog-content .blog-info .publisher .text span {
  /* display: block; */
  color: var(--text-p-color);
  font-size: 12px;
}

header + .blog-content .blog-info .mobile-share {
  display: flex;
  align-items: center;
  gap: 20px;
}

@media screen and (min-width: 601px) {
  header + .blog-content .blog-info .mobile-share {
    display: none !important;
  }
}

@media screen and (max-width: 600px) {
  header + .blog-content .blog-info .share,
  header + .blog-content .blog-info .share .share-list {
    display: none !important;
  }

  header + .blog-content .blog-info .publisher {
    flex-wrap: wrap;
    gap: 20px;
  }
}

@media screen and (max-width: 512px) {
  header + .blog-content .blog-info .publisher .text,
  header + .blog-content .blog-info .publisher .date {
    font-size: 12px;
  }

  header + .blog-content .blog-info .publisher .img-container .profile {
    width: 50px;
  }
}

@media screen and (max-width: 462px) {
  /* header+.blog-content .blog-info .publisher .text,
    header+.blog-content .blog-info .publisher .date {
        font-size: 10px;
    } */

  header + .blog-content .blog-info .publisher {
    flex-wrap: wrap;
    gap: 30px 5px;
  }
}

@media screen and (max-width: 408px) {
  header + .blog-content .blog-info .publisher,
  header + .blog-content .blog-info {
    flex-direction: column;
    align-items: center;
    gap: 20px 5px;
  }
}

header + .blog-content .blog-info .mobile-share svg {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

header + .blog-content .blog-info .mobile-share svg path {
  fill: var(--main-color);
}

header + .blog-content .blog-info .date {
  display: flex;
  align-items: center;
  gap: 10px;
}

header + .blog-content .blog-info .blog-info-paragraph {
  color: var(--text-p-color);
}

@media screen and (max-width: 522px) {
  header + .blog-content .blog-info .blog-info-paragraph {
    font-size: 15px;
  }
}

header + .blog-content .blog-info .user p {
  padding: 7px 15px;
  border-radius: 8px;
  background: rgba(253, 137, 0, 0.07);
  color: var(--main-color);
}

header + .blog-content .blog-info .share,
header + .blog-content .blog-info .share .share-list {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-left: auto;
}

.dir-rtl header + .blog-content .blog-info .share,
.dir-rtl header + .blog-content .blog-info .share .share-list {
  margin-right: auto;
  margin-left: 0;
}

header + .blog-content .blog-info .share .share-list svg {
  padding: 18px;
  background-color: var(--scm-bg);
  width: 70px;
  height: 70px;
  border-radius: var(--main-radius);
  -webkit-border-radius: var(--main-radius);
  -moz-border-radius: var(--main-radius);
  -ms-border-radius: var(--main-radius);
  -o-border-radius: var(--main-radius);
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

header + .blog-content .blog-info .share .share-list li:hover svg {
  background-color: var(--main-color);
}

header + .blog-content .blog-info .share .share-list svg path {
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
}

header + .blog-content .blog-info .share .share-list li:hover svg path {
  fill: white;
}

/* header + .blog-content .blog-content-heading { */
/* header + .blog-content .blog-body :where(h1, h2, h3, h4, h5, h6) */
header + .blog-content .blog-body :where(h1, h2, h3) {
  color: var(--main-color);
  line-height: var(--line-height);
  font-size: 40px;
  margin: 30px 0;
}
header + .blog-content .blog-body :where(h2) {
  font-size: 32px;
}
header + .blog-content .blog-body :where(h3) {
  font-size: 28px;
}

/* header + .blog-content .blog-content-paragraph  */
header + .blog-content p:not(.blogs-heading) {
  color: var(--text-white);
  line-height: var(--line-height);
  font-size: 22px;
  margin: 30px 0;
  /* font-family: en-300; */
}

.dir-rtl header + .blog-content p:not(.blogs-heading) {
  font-family: ar-300;
}

header + .blog-content .blog-body img {
  display: block;
  width: 60%;
  /* max-height: 370px !important; */
  min-height: 400px !important;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
  /* margin: 30px 0; */
  margin: 0 auto;
}

@media (max-width: 1400px) {
  header + .blog-content .blog-body img {
    width: 100%;
  }
}
@media (max-width: 850px) {
  header + .blog-content .blog-body img {
    object-fit: fill;
    min-height: unset !important;
  }
}

header + .blog-content hr {
  margin-bottom: 50px;
}

.blog.slider-controler {
  position: relative;
  margin-top: 50px;
  margin-bottom: 150px;
}

header + .blog-content .blogs-heading {
  color: var(--main-color);
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 30px;
  text-align: left;
}
.dir-rtl header + .blog-content .blogs-heading {
  text-align: right;
}

/* Skeleton styles */
header + .skeleton {
  margin-top: 120px !important;
}
.skeleton.blog-content {
  padding: 0 !important;
}
.skeleton.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.skeleton.logo > span {
  display: block;
  width: 100%;
  max-width: 100%;
}
.skeleton.img-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  margin-top: 50px;
}

.skeleton.img-container > span {
  display: block;
  width: 100%;
  max-width: 100%;
}

.skeleton.blog-body {
  margin-top: 50px;
}
.skeleton.blog-body > span {
  display: block;
  margin-top: 50px;
}
/* Responsive Design */

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 567px) {
}

@media screen and (max-width: 480px) {
}

/* Smallest smartphones */

@media screen and (max-width: 360px) {
}

/* Compact smartphones */

@media screen and (max-width: 320px) {
}

/* Very small smartphones */

@media screen and (max-width: 280px) {
}

/* Tiny smartphones */

/* Animations */
@keyframes grow {
  0% {
    scale: 0;
  }

  100% {
    scale: 1;
  }
}
